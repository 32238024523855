/* eslint-disable react-hooks/rules-of-hooks */
// Inspired by: https://github.com/excid3/tailwindcss-stimulus-components/blob/master/src/popover.js

import { Controller } from "@hotwired/stimulus";
import { createPopper, type Placement, type Instance } from "@popperjs/core";
import { useClickOutside, useHover } from "stimulus-use";

export class UIPopover extends Controller<HTMLElement> {
  static override values = {
    dismissAfter: Number,
  };

  static override targets = ["content", "wrapper", "trigger"];

  declare readonly hasContentTarget: boolean;

  declare readonly contentTarget: HTMLInputElement;

  declare readonly contentTargets: HTMLInputElement[];

  declare readonly hasWrapperTarget: boolean;

  declare readonly wrappertTarget: HTMLInputElement;

  declare readonly wrapperTargets: HTMLInputElement[];

  declare readonly hasTriggerTarget: boolean;

  declare readonly triggerTarget: HTMLInputElement;

  declare readonly triggerTargets: HTMLInputElement[];

  declare readonly hasDismissAfterValue: boolean;

  declare readonly dismissAfterValue: number;

  popperInstance: Instance | undefined;

  override connect() {
    useClickOutside(this);

    // display popover on hover
    if (this.contentTarget.dataset.hoverable === "true") {
      useHover(this, { element: this.triggerTarget });
    }

    this.popperInstance = createPopper(this.triggerTarget, this.contentTarget, {
      placement: (this.contentTarget.dataset.side as Placement) || "bottom",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ],
    });
  }

  // Show the popover
  show() {
    this.contentTarget.classList.remove("hidden");
    this.contentTarget.dataset.state = "open";
  }

  // Hide the popover
  hide() {
    this.contentTarget.classList.add("hidden");
    this.contentTarget.dataset.state = "closed";
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  clickOutside(_event: any) {
    this.hide();
  }

  // Toggle the popover on demand
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggle(_event: any) {
    this.popperInstance?.update();
    if (this.contentTarget.classList.contains("hidden")) {
      this.show();

      if (this.hasDismissAfterValue) {
        setTimeout(() => {
          this.hide();
        }, this.dismissAfterValue);
      }
    } else {
      this.hide();
    }
  }

  mouseEnter() {
    this.contentTarget.dataset.state = "open";
    this.contentTarget.classList.remove("hidden");
    this.popperInstance?.update();
  }

  mouseLeave() {
    this.contentTarget.dataset.state = "closed";
    this.contentTarget.classList.add("hidden");
    this.popperInstance?.update();
  }
}
