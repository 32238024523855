import { Application } from "@hotwired/stimulus";

import { UIControllers } from "./components";

declare global {
  interface Window {
    Stimulus: Application;
  }
}

window.Stimulus = Application.start();
window.Stimulus.register("ui--popover", UIControllers.popover);
window.Stimulus.register("ui--tooltip", UIControllers.tooltip);
