/* eslint-disable react-hooks/rules-of-hooks */

import { Controller } from "@hotwired/stimulus";
import { createPopper, type Instance, type Placement } from "@popperjs/core";
import { useDebounce, useHover } from "stimulus-use";

export class UITooltipController extends Controller<HTMLElement> {
  static debounces = ["mouseEnter", "mouseLeave"];

  static override targets = ["content", "wrapper", "trigger"];

  declare readonly hasContentTarget: boolean;

  declare readonly contentTarget: HTMLInputElement;

  declare readonly contentTargets: HTMLInputElement[];

  declare readonly hasWrapperTarget: boolean;

  declare readonly wrappertTarget: HTMLInputElement;

  declare readonly wrapperTargets: HTMLInputElement[];

  declare readonly hasTriggerTarget: boolean;

  declare readonly triggerTarget: HTMLInputElement;

  declare readonly triggerTargets: HTMLInputElement[];

  popperInstance: Instance | undefined;

  override connect() {
    useDebounce(this);
    useHover(this, { element: this.triggerTarget });
    this.popperInstance = createPopper(this.triggerTarget, this.contentTarget, {
      placement: (this.contentTarget.dataset.side as Placement) || "bottom",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ],
    });
  }

  mouseEnter() {
    this.contentTarget.dataset.state = "open";
    this.contentTarget.classList.remove("hidden");
    this.popperInstance?.update();
  }

  mouseLeave() {
    this.contentTarget.dataset.state = "closed";
    this.contentTarget.classList.add("hidden");
    this.popperInstance?.update();
  }
}
